import { Atom, Cmp } from ":mods";
import { Accordion } from "./accordion";
import { CSSStyler } from "./css-styler";
import { AMaterialLesson, AMaterialLessonItem } from "../../../../models";
import { MATERIAL_BUILDER_ITEM_TYPES } from "../../../../const";
const item_types_options = Object.keys(MATERIAL_BUILDER_ITEM_TYPES);
export function AccordionItem(props: {
  object: AMaterialLessonItem;
  title: string;
  // arrayKey: string;
  // newNamePrefix: string;
  onUpdate: (items: any) => void;
  // onSelected: (key: any, item: any, index: any) => void;
  onStyle: (item: any) => void;
}) {
  return (
    <Accordion
      title={props.title}
      disabled={!props.object}
      tabs={[
        {
          title: "settings",
          element: (
            <div>
              <Atom.Form.Select
                control={[props.object?.type]}
                class="form-select-side-panel"
                options={item_types_options}
                onselected={(value) => {
                  const s = props.object;
                  s.type = value;
                  props.onUpdate?.(s);
                }}
              />
              <Atom.Form.Input
                control={[props.object?.value]}
                update
                class="form-input-side-panel"
                onChange={(value) => {
                  const s = props.object;
                  s.value = value;
                  props.onUpdate?.(s);
                }}
              />
            </div>
          ),
        },
        {
          title: "style",
          element: (
            <CSSStyler
              style={props.object?.style}
              class="list-editable list-editable-side-panel"
              onChange={(style) => props.onStyle?.(style)}
            />
          ),
        },
      ]}
    />
  );
}
